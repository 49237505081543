import { useContext } from "react";
import { createContext, useReducer, useEffect } from "react";
import { useProductContext } from "./productcontext";
import reducer from "../reducer/filterReducer";
import axios from "axios";

const FilterContext = createContext();

const initialState = {
  filterProducts: [],
  allProducts: [],
  gridView: false,
  sorting_value: "lowest",
  filters: {
    text: "",
    category: "LAB",
  },
  search: false,
  loading: false,
  suggestedSearches: [],
};

const getFilteredProducts = async (name, category) => {
  const product = await axios.post(
    `${process.env.REACT_APP_BACKEND}/api/filter/ByName`,
    {
      name: name,
      index: category,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("authToken"),
      },
    }
  );

  return product.data.products;
};

const getSearchSuggestion = async (index, search) => {
  const suggestionArr = await axios.get(
    `${process.env.REACT_APP_BACKEND}/api/elasticsearch/searchInOneIndex`,
    {
      params: {
        indexName: index,
        searchTerm: search,
      },
    }
  );
  // console.log("suggestion array ---> ", suggestionArr);
  return suggestionArr.data.data;
};

export const FilterContextProvider = ({ children }) => {
  const { products } = useProductContext();

  const [state, dispatch] = useReducer(reducer, initialState);

  const setGridView = () => {
    return dispatch({ type: "SET_GRIDVIEW" });
  };

  const setListView = () => {
    return dispatch({ type: "SET_LISTVIEW" });
  };

  const toggleSearch = () => {
    return dispatch({ type: "TOGGLE_SEARCH" });
  };

  const sorting = (event) => {
    let userValue = event.target.value;
    dispatch({ type: "GET_SORT_VALUE", payload: userValue });
  };

  //update filter value
  const updateFilterValue = (obj) => {
    dispatch({
      type: "UPDATE_FILTER_VALUE",
      payload: { name: obj.name, value: obj.value },
    });

    if ((obj.name === "text") & (obj.value != "")) {
      getSearchSuggestion(state.filters.category, obj.value)
        .then((searchesarray) => {
          dispatch({ type: "SET_SUGGESTED_SEARCHES", payload: searchesarray });
        })
        .catch((error) => {
          console.error("Error fetching search suggestions:", error);
        });
    }
  };

  const clearFilters = () => {
    return dispatch({ type: "CLEAR_FILTERS" });
  };

  const setLoadingTrue = () => {
    return dispatch({ type: "SET_LOADING_TRUE" });
  };

  const setLoadingFalse = () => {
    return dispatch({ type: "SET_LOADING_FALSE" });
  };

  useEffect(() => {
    if (state.filters.text != "") {
      const fetchData = async () => {
        try {
          const filteredProducts = await getFilteredProducts(
            state.filters.text,
            state.filters.category
          );
          dispatch({ type: "FILTER_PRODUCTS", payload: { filteredProducts } });
          setLoadingFalse();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else {
      dispatch({ type: "LOAD_FILTER_PRODUCTS", payload: products });
    }
  }, [state.search]);

  useEffect(() => {
    dispatch({ type: "SORTING_PRODUCTS" });
  }, [state.sorting_value]);

  useEffect(() => {
    dispatch({ type: "LOAD_FILTER_PRODUCTS", payload: products });
  }, [products]);

  return (
    <FilterContext.Provider
      value={{
        ...state,
        setGridView,
        setListView,
        sorting,
        updateFilterValue,
        setLoadingTrue,
        setLoadingFalse,
        toggleSearch,
        clearFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
