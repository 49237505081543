import { MDBContainer } from "mdb-react-ui-kit";
import BillingAddress from "./components/checkout/BillingAddress";
import ShippingAddress from "./components/checkout/ShippingAddress";
import PaymentMethod from "./components/checkout/PaymentMethod";
import ImageUpload from "./components/checkout/ImageUpload";
import { useCartContext } from "./context/cartcontext";
import { useOrderContext } from "./context/ordercontext";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useState, useEffect } from "react";
import SideCart from "./components/checkout/SideCart";

const OrderByPresc = () => {
  const { total_price, shipping_fee } = useCartContext();
  const { state, cartDetails, updatePrescription } = useOrderContext();
  const [orderId, setOrderId] = useState(null);
  const [imageFiles, setImageFiles] = useState([]); // Array to hold multiple image files
  const [placingorder, setPlacingorder] = useState(false);

  const uploadImages = async () => {
    // Check if there are any files to upload
    if (imageFiles.length === 0) {
      return [];
    }

    // Initialize FormData and append all image files
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append("files", file); // Append each image file with the key 'files'
    });

    try {
      // Send a POST request to the backend to upload the images
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/order/uploadPrescription`,
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );

      // Extract the file URLs from the response and return them
      const uploadedImageUrls = response.data.fileUrls;
      return uploadedImageUrls; // Return the array of uploaded image URLs
    } catch (error) {
      console.error("Error uploading the images:", error);
      return []; // Return an empty array if the upload fails
    }
  };

  const offlinePayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND}/api/refferal/updateCredits/${orderId}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Referral updated successfully:", response.data);
          // Navigate to the success page after a successful API call
          window.location.href = "/success";
        } else {
          console.log("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error(
          "Error calling referral update API:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const placeOrderMongo = async (paymentStatus, imageUrls) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND}/api/order/placeOrder`,
        {
          ...state,
          paymentStatus: paymentStatus,
          prescription: {
            imageUrls, // Send array of image URLs
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        console.log("result of the order placed ---> ", res);
        setOrderId(res.data.orderId);
        localStorage.setItem("orderId", res.data.orderId);

        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Upload all images
      setPlacingorder(true);
      const imageUrls = await uploadImages();

      if (imageUrls.length === 0) {
        console.log("file urls ----.", imageUrls);
        toast.error("No prescription images uploaded");
        return;
      }

      // Place the order with the uploaded image URLs
      const paymentStatus =
        state.paymentDetails.modeOfPayment === "COD" ? "Success" : "Pending";
      const res = await placeOrderMongo(paymentStatus, imageUrls);
      console.log("Order placed successfully with prescription images:", res);
    } catch (error) {
      console.error("Error placing order:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (orderId) {
      if (state.paymentDetails.modeOfPayment === "COD") {
        offlinePayment();
      } else {
        // onlinePayment(); // Assuming you have an onlinePayment function
      }
    } else {
      console.error("Failed to place order, no orderId returned.. ");
    }
  }, [orderId]);

  return (
    <MDBContainer className="my-5 py-5" style={{ maxWidth: "1100px" }}>
      <ToastContainer />
      <h4 style={{ fontSize: "34px", fontWeight: 700, padding: "15px 0" }}>
        Order through prescription
      </h4>
      <h6>*After analyzing prescription we will contact you</h6>
      <form onSubmit={HandleSubmit}>
        <ImageUpload setImageFiles={setImageFiles} />{" "}
        {/* Pass the image files setter */}
        <BillingAddress />
        {/* <ShippingAddress /> */}
        <PaymentMethod />
        <div className="text-center">
          <button
            type="Submit"
            className="btn btn-success btn-block mx-auto"
            style={{
              height: "50px",
              fontSize: "16px",
              borderRadius: "10px",
              width: "50%",
            }}
            disabled={placingorder}
          >
            {placingorder ? "Placing Order ..." : "Place order via COD"}
          </button>
        </div>
      </form>
    </MDBContainer>
  );
};

export default OrderByPresc;
